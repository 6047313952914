import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";

/* Watch Page
============================================================================= */

const WatchPage = () => {
	// Config

	// Render
	return (
		<>
			<SEO title="Watch" />
			<div className="w-full">
				<div className="flex flex-row items-center justify-center w-full px-0 py-0 lg:bg-beige bg-orange lg:px-32 md:px-16 md:py-20">
					<div className="container flex flex-row items-center justify-start w-full py-5 lg:justify-center lg:h-180 lg:py-0">
						<div
							className="relative w-full h-0"
							style={{ paddingBottom: "56.25%" }}
						>
							<iframe
								src="https://player.twitch.tv/?channel=isolaidfestival&parent=www.isolaidfestival.com"
								allowFullScreen
								className="absolute w-full h-full"
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

/* Export
============================================================================= */

export default WatchPage;
